/* global $ */
/* eslint-disable no-invalid-this */

import { contactSubmit } from './contact.js';
import { AutomatitCarousel } from './automatit_carousel.m';

$(document).ready(function() {

	//> Mobile Navigation
	$('#menu_icon').click(function() {
		$(this).children('span').toggleClass('nav_open');
		$('#mobile_nav').toggleClass('show_nav');
	});

	$('nav li a').not("[target='_blank']").on('click touchend', function(){
		window.location = $(this).attr('href');
	});

	$('.more_less').click(function() {
		if ( $(this).hasClass('read_less') ) {
			$(this).removeClass('read_less');
			$(this).text('READ MORE');
			$(this).next().slideUp();
		} else {
			$(this).addClass('read_less');
			$(this).text('READ LESS');
			$(this).next().slideDown();
		}
	});

});


if(document.querySelector('#carousel')) {
	AutomatitCarousel({
		element: document.querySelector('#carousel'),
		showDots: true,
		useChevrons: false,
	});
}

if (document.getElementById('contact_form')) {
	contactSubmit();
}

if (document.getElementById('faqs')) {

	const questions = document.querySelectorAll('.question');

	questions.forEach(question => {
		question.addEventListener('click', () => {
			// Remove 'active' class and hide 'p' tag from all questions
			questions.forEach(q => {
				q.classList.remove('active');
				const answer = q.querySelectorAll('p');
				if (answer) {
					answer.forEach(ans => {
						ans.style.display = 'none';
					});
				}
			});

			// Add 'active' class and show 'p' tag for the clicked question
			question.classList.add('active');
			const answer = question.querySelectorAll('p');
			if (answer) {
				answer.forEach(ans => {
					ans.style.display = 'block';
				});
			}

			// Remove the 'rotate' class from all questions
			questions.forEach(q => {
				const h1 = q.querySelector('h1');
				if (h1) {
					h1.classList.remove('rotate');
				}
			});

			// Add the 'rotate' class to the clicked question's h1 element
			const h1 = question.querySelector('h1');
			if (h1) {
				h1.classList.add('rotate');
			}
		});
	});

	// Set the first question as active by default
	if (questions.length > 0) {
		questions[0].click();
	}
}
